export const territories = [
  "",
  "Albania",
  "Argentina",
  "Australia",
  "Brazil",
  "Canada",
  "China",
  "Denmark",
  "Finland",
  "France",
  "Germany",
  "Austria",
  "Hong Kong",
  "West Asia/North Africa",
  "India",
  "Italy",
  "Japan",
  "Korea, South",
  "Malaysia",
  "Mexico",
  "Netherlands",
  "Nigeria",
  "Pakistan",
  "Philippines",
  "Russia",
  "Singapore",
  "Sweden",
  "Thailand",
  "United Kingdom",
  "United States",
  "Vietnam",
];

export const terms = [
  "",
  "1 Year",
  "2 Years",
  "3 Years",
  "4 Years",
  "5 Years",
  "6 Years",
  "7 Years",
];

export const exhibitionTypes = [
  "",
  "Theatrical",
  "Broadcast",
  "AVOD",
  "TVOD",
  "SVOD",
  "Promotional",
];

export const genreList = [
  "",
  "Action",
  "Adventure",
  "Art House",
  "Comedy",
  "Drama",
  "Science Fiction",
  "Mystery",
  "Suspense",
  "Thriller",
  "Female-Driven",
  "Non-Fiction",
  "Reality",
  "Romance",
  "Romantic Comedy",
  "Situational Comedy",
  "Religious",
  "Fantasy",
  "Dark Comedy",
];

export const projectTypeList = [
  "",
  "Feature",
  "Episodic",
  "Short",
  "Webseries"
];

export const initialMetadata = {
  filmName: "",
  reservePrice: "",
  thumbnail: "",
  territory: "",
  term: "",
  exhibitionType: "",
  genre: "",
  genre2: "",
  genre3: "",
  contractFile: "",
  imbdLink: "",
  screenerLink: "",
  royaltyPerc: "",
};
